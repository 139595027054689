import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Headline } from '../components/elements/Typography';
import { Quote } from '../components/elements/Quote';
import juryBoes from '../images/content/jury2020/boes.jpg';
import juryHaasis from '../images/content/jury2020/hassis.jpg';
import jurySchreiner from '../images/content/jury2020/schreiner.jpg';
import juryWeinmann from '../images/content/jury2020/weinmann.jpg';
import juryTMueller from '../images/content/jury2020/mueller.jpg';
import juryMMueller from '../images/content/jury2020/mmueller.jpg';
import juryKoehler from '../images/content/jury2020/koehler.jpg';
import juryZoellner from '../images/content/jury2020/zoeller.jpg';
import juryPorada from '../images/content/jury2020/porada.jpg';
import juryJakob from '../images/content/jury2020/jakob.jpg';

const AboutPage = () => (
    <BaseLayout title="Die Jury" description="Die Jury">
        <Stage>
            <StageIntro
                headline={{ text: 'Über den LOTTO Sportjugend-Förderpreis', smaller: true }}
                copy="Der Wettbewerb für baden-württembergische Sportvereine."
            />
        </Stage>

        <Section container="m">
            <Headline level="h2">
                Zehn Jurorinnen und Juroren übernehmen die Aufgabe, die Preisträger zu ermitteln
            </Headline>
            <Quote
                author="Prof. Dr. Klaus Bös<br/>Professor für Sportwissenschaft am KIT (emeritiert)"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryBoes}
            >
                Der Sportjugendförderpreis „Lotto Award“, an dem sich zuletzt über 450 Vereine in
                Baden-Württemberg beteiligten, zeigt stets aufs Neue die Kreativität und das überaus
                große Engagement, das unsere Vereine für die Kinder und Jugendlichen Jahr für Jahr
                aufbringen. Der besondere Dank gilt den Trägern Staatliche Lotto GmbH
                Baden-Württemberg und Sportjugend Baden-Württemberg, dass sie diesen Preis
                ermöglichen.
            </Quote>

            <Quote
                author="Elvira Menzer-Haasis<br/>Präsidentin LSVBW"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryHaasis}
            >
                Für rund 1,6 Millionen Kinder und Jugendliche in Baden-Württemberg stellt der
                Sportverein, neben Schule und Elternhaus, den Ort dar, an dem soziale Kompetenzen
                vorgelebt und gelernt werden. Insbesondere das ehrenamtliche Engagement im
                organisierten Sport ist dabei die Grundlage für gemeinschaftliche Erlebnisse.
                Bereits zum zwölften Mal würdigt der Lotto Sportjugend-Förderpreis das herausragende
                ehrenamtliche Engagement Jugendlicher im Sportverein.
            </Quote>

            <Quote
                author="Michael Schreiner<br/>Ministerium für Kultus, Jugend und Sport BW"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={jurySchreiner}
            >
                Die Vielfältigkeit der baden-württembergischen Sportvereine vor Augen geführt zu
                bekommen und deren großes ehrenamtliches Engagement in der Kinder- und Jugendarbeit
                würdigen zu können, macht für mich den Reiz des SJFP aus.
            </Quote>

            <Quote
                author="Susanne Weimann<br/>Geschäftsführender Vorstand Kinderturnstiftung BW"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryWeinmann}
            >
                Im Rahmen meiner Bachelorarbeit hat mich die Frage beschäftigt, ob kleine Vereine
                genauso große Chancen haben beim Sportjugendförderpreis zu gewinnen wie große
                Vereine. Das Ergebnis: Ja. Entscheidend ist nicht die Größe des Vereins, sondern die
                Qualität des Engagements und die Anzahl der Jugendlichen, die aktiv sind!
            </Quote>

            <Quote
                author="Tobias Müller<br/>Vorsitzender BWSJ"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryTMueller}
            >
                Mit dem SJFP möchten wir Projekte von Vereinen auszeichnen, die nachhaltig
                ehrenamtliches Engagement und Projektarbeit von jungen Menschen fördern und
                begleiten.
            </Quote>

            <Quote
                author="Magnus Müller<br/>Vorsitzender BSB Nord"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryMMueller}
            >
                Unsere Vereine leisten großartige Arbeit, vor allem im Kinder- und Jugendbereich.
                Dieser Einsatz verdient Anerkennung und Wertschätzung. Mit dem
                Sportjugendförderpreis haben wir die Möglichkeit, die tollen Ideen mit
                Modellcharakter auch sichtbar zu machen.
            </Quote>

            <Quote
                author="Anne Köhler<br/>Vorsitzende WSJ"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryKoehler}
            >
                Mit dem Sportjugendförderpreis erhalten die unzähligen wertvollen Projekte und
                Aktionen in unseren vielfältigen Vereinen die Aufmerksamkeit, die sie verdienen. Das
                grandiose ehrenamtliche Engagement, welches in der Jugendarbeit in unseren
                Sportvereinen erbracht wird, ist keine Selbstverständlichkeit. Dieses wollen wir
                würdigen und belohnen!
            </Quote>

            <Quote
                author="Jens Jakob<br/>Vorsitzender BSJ Freiburg"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryJakob}
            >
                Die Jugendarbeit im Sport fördert die Persönlichkeitsentwicklung junger Menschen.
                Durch echte Beteiligung in den Vereinen machen junge Menschen Erfahrungen weit über
                das reine Sporttreiben hinaus. Diese Jugendarbeit unterstützt der
                Sportjugendförderpreis.
            </Quote>

            <Quote
                author="Martina Zöllner<br/>Sprecherin BWSJ JuniorTeam"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryZoellner}
            >
                Mit vielen kreativen und wertvollen Projekten und Aktionen sind Sportvereine
                grundlegend an der Entwicklung von Kindern und Jugendlichen beteiligt und prägen
                deren Persönlichkeit. Der Lotto Award würdigt dieses tolle Engagement von und für
                junge Menschen.
            </Quote>

            <Quote
                author="Lisa Porada<br/>Leitung BWSJ"
                gap={{ small: 'xl', medium: 'xxl', large: 'xxxl' }}
                image={juryPorada}
            >
                Der Sport stellt das größte Teilhabe- und Engagementfeld für Kinder und Jugendliche
                dar. Er eignet sich daher auch sehr gut dafür, dass Kinder und Jugendliche die
                Möglichkeit erhalten, sich auszuprobieren und Erfahrungen zu sammeln. Der
                Sportjugendförderpreis möchte genau dieses Vertrauen, dass Vereine in ihren
                Nachwuchs stecken, auszeichnen.
            </Quote>
        </Section>
    </BaseLayout>
);

export default AboutPage;
